import logo from "~/screens/work/assets/rentroom/logo.png"
import p01 from "~/screens/work/assets/rentroom/01.png"
import p02 from "~/screens/work/assets/rentroom/02.png"
import p03 from "~/screens/work/assets/rentroom/03.png"
import p04 from "~/screens/work/assets/rentroom/04.png"
import p05 from "~/screens/work/assets/rentroom/05.png"
import p06 from "~/screens/work/assets/rentroom/06.png"

export default {
  logo,
  photos: [p06, p02, p01, p05, p03, p04],
}
