import logo from "~/screens/work/assets/avantstay/logo.png"
import p01 from "~/screens/work/assets/avantstay/01.png"
import p02 from "~/screens/work/assets/avantstay/02.png"
import p03 from "~/screens/work/assets/avantstay/03.png"
import p04 from "~/screens/work/assets/avantstay/04.png"
import p05 from "~/screens/work/assets/avantstay/05.png"

export default {
  logo,
  photos: [p01, p02, p03, p04, p05],
}
